.input-form::-webkit-input-placeholder {
  opacity: 0.4;
  font-weight: normal;
  transition: opacity 0.5s ease;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.input-form::-moz-placeholder {
  opacity: 0.4;
  font-weight: normal;
  transition: opacity 0.5s ease;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.input-form:-moz-placeholder {
  opacity: 0.4;
  font-weight: normal;
  transition: opacity 0.5s ease;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.input-form:-ms-input-placeholder {
  opacity: 0.4;
  font-weight: normal;
  transition: opacity 0.5s ease;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}

.input-form:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.input-form:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.input-form:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.input-form:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.5s ease;
}

input[placeholder] {
  text-overflow: ellipsis;
}

input::-moz-placeholder {
  text-overflow: ellipsis;
}

input:-moz-placeholder {
  text-overflow: ellipsis;
}

input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
