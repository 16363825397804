/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #1890ff;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #1890ff;
}
.ant-tag-checkable:active {
  background-color: #096dd9;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #d4380d;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #d46b08;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #d4b106;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #d48806;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #08979c;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #7cb305;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #096dd9;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #1d39c4;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #531dab;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #ff4d4f;
  background: #fff2f0;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-picker-status-error.ant-picker-focused,
.ant-picker-status-error.ant-picker:focus {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-error.ant-picker .ant-picker-active-bar {
  background: #ff7875;
}
.ant-picker-status-warning.ant-picker,
.ant-picker-status-warning.ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #faad14;
}
.ant-picker-status-warning.ant-picker-focused,
.ant-picker-status-warning.ant-picker:focus {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-status-warning.ant-picker .ant-picker-active-bar {
  background: #ffc53d;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-picker-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
  user-select: none;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #40a9ff;
  border-right-width: 1px;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  display: flex;
  flex: none;
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-suffix > *:not(:last-child) {
  margin-right: 8px;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #1890ff;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 2.58561808px;
  display: block;
  transform: rotate(-135deg) translateY(1px);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 2.58561808px;
  display: block;
  transform: rotate(45deg);
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-enter.ant-slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-appear.ant-slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.ant-slide-up-leave.ant-slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 7.54247233px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 11.3137085px;
  height: 11.3137085px;
  margin-left: 16.5px;
  box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-out;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-picker-range-arrow::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel .ant-picker-content,
.ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  margin-right: -1px;
}
.ant-picker-compact-item:not(.ant-picker-compact-last-item).ant-picker-compact-item-rtl {
  margin-left: -1px;
}
.ant-picker-compact-item:hover,
.ant-picker-compact-item:focus,
.ant-picker-compact-item:active {
  z-index: 2;
}
.ant-picker-compact-item.ant-picker-focused {
  z-index: 2;
}
.ant-picker-compact-item[disabled] {
  z-index: 0;
}
.ant-picker-compact-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-last-item).ant-picker {
  border-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-first-item:not(.ant-picker-compact-last-item):not(.ant-picker-compact-item-rtl) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-last-item:not(.ant-picker-compact-first-item):not(.ant-picker-compact-item-rtl) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-first-item:not(.ant-picker-compact-last-item) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-picker-compact-item.ant-picker.ant-picker-compact-item-rtl.ant-picker-compact-last-item:not(.ant-picker-compact-first-item) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #1890ff;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #1890ff;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentcolor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #1890ff;
  border-radius: 2px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #1890ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #e6f7ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #7ec1ff;
  border-bottom: 1px dashed #7ec1ff;
  transform: translateY(-50%);
  transition: all 0.3s;
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #cbe6ff;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #cbe6ff;
  transition: all 0.3s;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #7ec1ff;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #7ec1ff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: rgba(0, 0, 0, 0.04);
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #1890ff;
}
.ant-picker-today-btn:hover {
  color: #40a9ff;
}
.ant-picker-today-btn:active {
  color: #096dd9;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #7ec1ff;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #1890ff;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(230, 247, 255, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #e6f7ff;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 2px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #7ec1ff;
  border-left: none;
  border-radius: 0 2px 2px 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px 0 0 2px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #7ec1ff;
  border-left: 1px dashed #7ec1ff;
  border-radius: 2px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  max-width: 100%;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  width: max-content;
  max-width: 100%;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  display: flex;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message-icon {
  display: inline-block;
  margin-right: 8px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button:not(:first-child) {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  --antd-arrow-background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  width: max-content;
  width: intrinsic;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-content {
  position: relative;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 14.3137085px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 14.3137085px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 14.3137085px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 14.3137085px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  z-index: 2;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.75));
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-tooltip-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: var(--antd-arrow-background-color);
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  clip-path: inset(33% 33%);
  clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(315deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(11px) rotate(225deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content::before {
  background: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content::before {
  background: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content::before {
  background: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content::before {
  background: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content::before {
  background: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content::before {
  background: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content::before {
  background: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content::before {
  background: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content::before {
  background: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content::before {
  background: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content::before {
  background: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content::before {
  background: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #91d5ff;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #91d5ff;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #46a6ff;
  outline: none;
  box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #1890ff;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #69c0ff;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #69c0ff;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #8cc8ff;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
/* stylelint-disable property-no-vendor-prefix, at-rule-no-vendor-prefix */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #1890ff;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon > .anticon {
  line-height: 0;
  vertical-align: 0;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin,
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.ant-fade-enter,
.ant-fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.ant-fade-leave.ant-fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.ant-fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.ant-move-up-enter,
.ant-move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-up-enter.ant-move-up-enter-active,
.ant-move-up-appear.ant-move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.ant-move-up-leave.ant-move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-up-enter,
.ant-move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-down-enter,
.ant-move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-down-enter.ant-move-down-enter-active,
.ant-move-down-appear.ant-move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.ant-move-down-leave.ant-move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-down-enter,
.ant-move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-left-enter,
.ant-move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-left-enter.ant-move-left-enter-active,
.ant-move-left-appear.ant-move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.ant-move-left-leave.ant-move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-left-enter,
.ant-move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.ant-move-right-enter,
.ant-move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-move-right-enter.ant-move-right-enter-active,
.ant-move-right-appear.ant-move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.ant-move-right-leave.ant-move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-move-right-enter,
.ant-move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #1890ff;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #1890ff;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #1890ff;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-up-enter.ant-slide-up-enter-active,
.ant-slide-up-appear.ant-slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.ant-slide-up-leave.ant-slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-up-enter,
.ant-slide-up-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-down-enter.ant-slide-down-enter-active,
.ant-slide-down-appear.ant-slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.ant-slide-down-leave.ant-slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-down-enter,
.ant-slide-down-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-left-enter.ant-slide-left-enter-active,
.ant-slide-left-appear.ant-slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.ant-slide-left-leave.ant-slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-left-enter,
.ant-slide-left-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-slide-right-enter.ant-slide-right-enter-active,
.ant-slide-right-appear.ant-slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.ant-slide-right-leave.ant-slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-slide-right-enter,
.ant-slide-right-appear {
  transform: scale(0);
  transform-origin: 0% 0%;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.ant-slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.ant-zoom-enter,
.ant-zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.ant-zoom-leave.ant-zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}
.ant-zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-leave.ant-zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}
.ant-zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}
.ant-zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.ant-zoom-up-leave.ant-zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}
.ant-zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.ant-zoom-down-leave.ant-zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}
.ant-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.ant-zoom-left-leave.ant-zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}
.ant-zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.ant-zoom-right-leave.ant-zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}
.ant-zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -0.5px;
  color: #1890ff;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #1890ff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #1890ff;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #1890ff;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4d4f;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #ff4d4f;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #ff4d4f;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #1890ff;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #1890ff;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #1890ff;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 15px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 11px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 13px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 6.5px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-top: 10px;
}
.ant-steps-vertical.ant-steps-dot.ant-steps-small .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 3.5px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  top: -1px;
  left: -1px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #1890ff;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-navigation.ant-steps-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title .ant-steps-item-subtitle {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item {
  padding-right: 4px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 19px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-small.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 15px;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child {
  padding-right: 4px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-with-progress.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:first-child.ant-steps-item-active {
  padding-right: 4px;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 4px;
  left: 19px;
}
.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child,
.ant-steps-with-progress.ant-steps-small.ant-steps-horizontal .ant-steps-item:first-child {
  padding-bottom: 4px;
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-small > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 15px;
}
.ant-steps-with-progress.ant-steps-vertical .ant-steps-item {
  padding-left: 4px;
}
.ant-steps-with-progress.ant-steps-label-vertical .ant-steps-item .ant-steps-item-tail {
  top: 14px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
